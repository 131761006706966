import fetch from 'isomorphic-fetch'

import { Group } from '@bff/models/bff/group'
import { Brand } from '@bff/models/og/brand'
import { ErrorResponse } from '@bff/models/og/menu'
import { Filter} from 'bff/models/bff/group-filters'
import { NEXTJS_API_PATH, SERVER_API_URL, WWW_BASE_URL } from 'bff/utils/constants'

type BrandGroupPrams = {
  slug: string
  placeId?: string
}

/**
 * Makes and fetches client-side brand group and filters based on the provided request parameters.
 * Sends request to pages/api/v2/brand/[slug]/index.ts aka /api/v2/brand/:slug
 *
 * @param request - An object containing the parameters for the brand group.
 * @param request.slug - The slug of the brand to fetch.
 * @param request.placeId - Optional. The place ID associated with the brand.
 *
 * @returns A promise that resolves to an object containing either an error response or the fetched data.
 * The data object includes the brand group and group filters.
 * If an error occurs during the fetch, the error details are returned in the err property,
 * and the data property is set to null.
 */
export default async function getClientSideBrandGroupAndFilters(
  request: BrandGroupPrams
): Promise<{ err: ErrorResponse; data: { group: Group, groupFilters: Filter[] } }> {
  let result = null
  let statusCode: number = null
  const apiRoute = buildURL(request)

  try {
    const response = await fetch(apiRoute)

    result = await response.json()

    statusCode = response.status

    if (!response.ok) {
      const errorResult = result as ErrorResponse
      throw Error(errorResult.message)
    }
  } catch (err) {
    console.error(`Error fetching brand group for ${request.slug} and ${request.placeId}`, err)
    return { err: { message: result?.message, statusCode } as ErrorResponse, data: null }
  }
  return { err: null, data: result }
}

function buildURL(request: BrandGroupPrams): URL {
  const apiRoute = new URL(`${NEXTJS_API_PATH}/brand/${request.slug}`, WWW_BASE_URL)
  if (request?.placeId) apiRoute.searchParams.append('placeId', request.placeId)
  return apiRoute
}

export async function getClientSideBrandCatalog(): Promise<{ err: ErrorResponse; data: Brand[] }> {
  let result: Partial<any> = {}

  const URL = `${SERVER_API_URL}/catalog/brands?is_ecommerce=true`

  try {
    const response = await fetch(URL)
    const statusCode = response.status
    result = await response.json()
    if (statusCode !== 200) {
      const errorResult = result as ErrorResponse
      throw Error(errorResult.message)
    }
  } catch (err) {
    console.error(`Error fetching brands catalog`, err)
    return { err: result as ErrorResponse, data: null }
  }
  return { err: null, data: result as Brand[] }
}
